.login-page-gradient {
  background: linear-gradient(
    180deg,
    rgba(250, 250, 250, 1) 0%,
    rgba(215, 215, 215, 1) 74%,
    rgba(228, 228, 228, 1) 83%,
    rgba(228, 228, 228, 1) 100%
  );
  min-height: 300px;
}
.login-div {
  background: rgb(0 0 0 / 80%);
  min-height: 300px;
  border-radius: 10px;
  z-index: 1;
}
.login-form-group {
  padding: 0 20%;
  margin-top: 5%;
  color: black;
}
.input-icons-n {
  width: 100%;
}
.icon {
  padding-right: 12px;
  border-right: 0;
  border-left: 1px solid #dddddd;
}
.fa-user:before {
  content: "\f007";
}
.input-icons-n input {
  border: 1px solid #dddddd;
  background-color: #ffffff;
  border-radius: 2.5rem;
  height: 45px;
  font-size: 16px;
  padding-right: 42px;
  padding-left: 0;
  text-align: right;
}
.input-field {
  width: 100%;
  padding: 10px;
}
.login-form-group button {
  height: 45px;
  border-radius: 2.5rem;
  background-color: #fff100;
  font-size: 16px;
  color: #000000 !important;
  border: 1px solid #fff100;
  width: 100%;
}
.login-form-group button:hover {
  background-color: #000000;
  color: #ffffff !important;
}
.icon-style {
  padding-right: 12px;
  border-right: 0;
  border-left: 1px solid #dddddd;
  min-width: 32px;
  font-size: 15px;
  color: #999999;
  margin-top: 14px;
  position: absolute;
}
.icon-style-ltr {
  position: absolute;
  margin-top: 7px;
  padding-left: 12px;
  height: 32px;
  border-left: 0;
  border-right: 1px solid #dddddd;
  padding-top: 10px;
}

.input-icons-n-ltr input {
  border: 1px solid #dddddd;
  background-color: #ffffff;
  border-radius: 2.5rem;
  height: 45px;
  font-size: 16px;
  padding-right: 0;
  padding-left: 42px;
  text-align: left;
}
.background-image {
  background-image: url("../images/bodybg.jpg");
  height: 100%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.wrapper-div {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.login-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
  height: 200px;
  border-radius: 100px;
}
