// 
// _table.scss
// 

.table {
  th {
      font-weight: $font-weight-bold;
  }
}

.table {
  thead {
    position: sticky;
    top:0;
    tr{    
      th {
        vertical-align: top;
        color:#fff;
        background-color: #332d2d;
      }
    }
  }  
}

//Table centered
.table-centered {
  td,th {
      vertical-align: middle !important;
  }
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}